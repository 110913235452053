import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL;

const Authentication = {
  loginHandler: (payload) => {
    const response = axios({
      url: `${baseUrl}/admin/nvt-core/login`,
      method: "POST",
      data: JSON.stringify({
        email: payload.email,
        password: payload.password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  getAllAdmins: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/nvt-core/admins`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  addNewAdmin: (payload) => {
    const response = axios({
      url: `${baseUrl}/admin/nvt-core/register`,
      method: "POST",
      data: JSON.stringify({
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        role: payload.role,
        password: payload.password,
        confirmPassword: payload.confirmPassword,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },
};
export default Authentication;
