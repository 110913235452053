import { createRouter, createWebHistory } from "vue-router";
import LogIn from "../views/auth/LogIn.vue";
import Shipments from "../views/shipments/ShipMents.vue";
import ShipmentsTable from "../views/shipments/ShipmentsTable.vue";
import Procurement from "../views/procurement/Procurement.vue";
import ProcurementTable from "../views/procurement/ProcurementTable.vue";
import ProcurementItem from "../views/procurement/ProcurementItem.vue";
import ShipmentItem from "../views/shipments/ShipmentItem.vue";
import SupplierPayments from "../views/SupplierPayments/SupplierPayments.vue";
import SupplierPaymentTable from "../views/SupplierPayments/SupplierPaymentTable.vue";
import SupplierPaymentOverview from "../views/SupplierPayments/SupplierPaymentOverview.vue";
import store from "../store/Store";
import ExchangeRates from "@/views/exchange-rates/ExchangeRates.vue";
import ExchangeRatesTable from "@/views/exchange-rates/ExchangeRatesTable.vue";
import ExchangeRate from "@/views/exchange-rates/ExchangeRate.vue";
import NewExchangeRate from "@/views/exchange-rates/NewExchangeRate.vue";
import AddNewAdmin from "@/views/auth/AddNewAdmin.vue";
import AllAdmins from "@/views/auth/AllAdmins.vue";

const requireAuth = (to, from, next) => {
  const token = store.state.accessToken;
  if ((to.path === "/login" || to.path === "/reset-password") && token) {
    // Skip login if already authenticated
    next({ path: "/" }); // Redirect to home or desired route
  } else if (!token && to.matched.some((record) => record.meta.requiresAuth)) {
    // Redirect to login if accessing protected route without token
    next({ path: "/login" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/login",
    name: "LogIn",
    component: LogIn,
    beforeEnter: requireAuth,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/all-admins",
    name: "admins",
    component: AllAdmins,
    children: [
      {
        path: "add-admin",
        name: "AddNewAdmin",
        component: AddNewAdmin,
      },
    ],
    beforeEnter: requireAuth,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/add-admin",
    name: "AddNewAdmin",
    component: AddNewAdmin,
    beforeEnter: requireAuth,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/shipments",
    name: "shipments",
    component: Shipments,
    children: [
      {
        path: "",
        component: ShipmentsTable,
      },

      {
        name: "shipmentItem",
        path: "/shipment-item/:itemId",
        component: ShipmentItem,
      },
    ],
    beforeEnter: requireAuth,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/exchangeRates",
    name: "exchange-rates",
    component: ExchangeRates,
    children: [
      {
        path: "",
        component: ExchangeRatesTable,
      },
      {
        path: "new-exchange-rate",
        component: NewExchangeRate,
      },
      {
        name: "rate",
        path: "/rate/:itemId",
        component: ExchangeRate,
      },
    ],
    beforeEnter: requireAuth,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/procurement",
    name: "procurement",
    component: Procurement,
    children: [
      {
        path: "",
        component: ProcurementTable,
      },

      {
        name: "procurementItem",
        path: "/procurement-item/:itemId",
        component: ProcurementItem,
      },
    ],
    beforeEnter: requireAuth,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/supplier-payments",
    name: "supplier-payments",
    component: SupplierPayments,
    children: [
      {
        path: "",
        component: SupplierPaymentTable,
      },

      {
        name: "supply-payment-overview",
        path: "/supplier-payments/:itemId",
        component: SupplierPaymentOverview,
      },
    ],
    beforeEnter: requireAuth,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
