<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <circle-loader v-if="isLoading" />
  <div class="procure" v-else>
    <div class="procure_inner">
      <h1>Create a new exchange rate</h1>

      <form class="procure_form" @submit.prevent="createExchangeRatesHandler">
        <div class="shipments_select">
          <label>Currency</label>
          <div class="input">
            <input
              v-model="currency"
              required
              type="text"
              placeholder="Enter currency name"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Currency Abbreviation</label>
          <div class="input">
            <input
              v-model="currencyAbbreviation"
              required
              type="text"
              placeholder="Enter currency abbreviation link "
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Rate in NGN</label>
          <div class="input">
            <input
              v-model="rate"
              required
              type="number"
              min="0"
              placeholder="Enter rate in naira"
              class="input_field"
            />
          </div>
        </div>

        <div v-if="!submitLoading" class="button">
          <button class="button_btn">Submit</button>
        </div>

        <div v-else class="button">
          <button :disabled="submitLoading" class="button_btn">
            <div class="loading-circle"></div>
          </button>
        </div>
      </form>

      <h1>Create a new shipping rate</h1>

      <form class="procure_form" @submit.prevent="createShippingRatesHandler">
        <div class="shipments_select">
          <label>Freight Type</label>
          <div class="shipments_select">
            <select required v-model="freightType" class="input_field">
              <option value="">Freight Type</option>
              <option value="AIR_FREIGHT">AIR FREIGHT</option>
              <option value="SEA_FREIGHT">SEA FREIGHT</option>
            </select>
          </div>
        </div>

        <div class="shipments_select">
          <label>Weight Unit</label>
          <div class="shipments_select">
            <select required v-model="weightUnit" class="input_field">
              <option value="">Weight Unit</option>
              <option value="KG">KG</option>
              <option value="LB">LB</option>
              <option value="CBM">CBM</option>
            </select>
          </div>
        </div>

        <div class="shipments_select">
          <label>Currency</label>
          <div class="shipments_select">
            <select required v-model="rateCurrency" class="input_field">
              <option value="">Currency</option>
              <option value="$">USD</option>
              <option value="£">GBP</option>
              <option value="₦">Naira</option>
              <option value="CAD$">CAD</option>
            </select>
          </div>
        </div>

        <div class="shipments_select">
          <label>Rate</label>
          <div class="input">
            <input
              v-model="shippingRate"
              required
              type="text"
              pattern="[0-9]*([.,][0-9]+)?"
              min="0"
              placeholder="Enter rate"
              class="input_field"
            />
          </div>
        </div>

        <div class="shipments_select">
          <label>Location</label>
          <div class="shipments_select">
            <select required v-model="location" class="input_field">
              <option value="">Location</option>
              <option value="NIGERIA">Nigeria</option>
              <option value="USA">USA</option>
              <option value="UK">UK</option>
              <option value="CHINA">CHINA</option>
              <option value="CANADA">CANADA</option>
            </select>
          </div>
        </div>

        <div class="shipments_select">
          <label>Destination</label>
          <div class="shipments_select">
            <select required v-model="destination" class="input_field">
              <option value="">Destination</option>
              <option value="NIGERIA">Nigeria</option>
              <option value="USA">USA</option>
              <option value="UK">UK</option>
              <option value="CHINA">CHINA</option>
              <option value="CANADA">CANADA</option>
            </select>
          </div>
        </div>

        <div class="shipments_select">
          <label>Special Good?</label>
          <div class="shipments_select">
            <select required v-model="isSpecialGoods" class="input_field">
              <option value="">Special Good?</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>

        <div v-if="!submitLoading" class="button">
          <button class="button_btn">Submit</button>
        </div>

        <div v-else class="button">
          <button :disabled="submitLoading" class="button_btn">
            <div class="loading-circle"></div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ExchangeRatesApi from "@/utils/api/Exchange-rates/ExchangeRatesApi";
import CircleLoader from "../../components/loader/CircleLoader.vue";

export default {
  data() {
    return {
      currency: "",
      rateCurrency: "",
      currencyAbbreviation: "",
      rate: "",
      freightType: "",
      weightUnit: "",
      location: "",
      shippingRate: "",
      destination: "",
      isSpecialGoods: "",
      isLoading: false,
      item: null,
      submitLoading: false,
    };
  },
  components: {
    CircleLoader,
  },
  mounted() {},
  methods: {
    async createExchangeRatesHandler() {
      const token = this.$store.state.accessToken;

      this.submitLoading = true;

      try {
        const exchangeRateRes = await ExchangeRatesApi.createRate({
          currency: this.currency,
          currencyAbbreviation: this.currencyAbbreviation,
          amount: this.rate,
          access_token: token,
        });

        this.submitLoading = true;

        this.$toast.success("Form has been submitted successfully");

        // route to procurement list page after successful procurement of item
        this.$router.push("/exchangeRates");

        return exchangeRateRes.data;
      } catch (err) {
        this.submitLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data?.message || err.response?.data?.error
          );
        }
      }
    },

    async createShippingRatesHandler() {
      const token = this.$store.state.accessToken;

      this.submitLoading = true;

      try {
        const exchangeRateRes = await ExchangeRatesApi.createShippingRates({
          freightType: this.freightType,
          weightUnit: this.weightUnit,
          rate: this.shippingRate,
          location: this.location,
          destination: this.destination,
          currency: this.rateCurrency,
          isSpecialGoods: this.isSpecialGoods,
          access_token: token,
        });

        this.submitLoading = true;

        this.$toast.success("Form has been submitted successfully");

        // route to procurement list page after successful procurement of item
        this.$router.push("/exchangeRates");

        return exchangeRateRes.data;
      } catch (err) {
        this.submitLoading = false;
        // Logout if token has expired.
        if (err?.response?.data?.code === 403) {
          this.$store.dispatch("clearSignUpDetails");
          window.location.reload();
        } else {
          this.$toast.error(
            err.response?.data?.message || err.response?.data?.error
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.procure {
  width: 100%;
  padding: 2.75rem 5.125em;

  @media only screen and (max-width: 920px) {
    padding: 0rem 0.5em;
  }

  .procure_inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: auto;
    flex-shrink: 0;
    padding: 1.5625rem 0rem;
    border-radius: 1.875rem;
    gap: 2.8125rem;
    border: 1px solid #dedce9;
    background: #fafafa;
    align-items: center;

    @media only screen and (max-width: 920px) {
      overflow: visible;
    }

    h1 {
      margin-top: 2.1875rem;
      color: #000;
      font-family: $primary-font;
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @media only screen and (max-width: 920px) {
        font-size: 1.5625rem;
        margin-top: 0.3125rem;
      }
    }

    .procure_form {
      display: flex;
      flex-direction: column;
      gap: 2.8125rem;
      width: 29.1875em;
      // overflow: scroll;

      .loading-circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid #fff;
        position: relative;
        animation: rotate 1s linear infinite;
      }

      .loading-circle::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);
        animation: bounce 0.6s linear infinite;
      }

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      @keyframes bounce {
        0%,
        100% {
          transform: scale(1);
        }
        50% {
          transform: scale(0);
        }
      }

      @media only screen and (max-width: 920px) {
        width: 100%;
        padding: 0px 0.5rem;
      }

      .shipments_image {
        border-radius: 0.625rem;
        width: 100%;
        height: 9.1875em;
        gap: 0.625rem;
        display: flex;
        flex-direction: column;

        .input_image {
          width: 100%;
          height: 9.1875em;
          flex-shrink: 0;
          border-radius: 0.3125rem;
          border: 0.0625rem solid #c4c1c1;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          justify-content: center;

          img {
            width: 100%;
            height: 100px;
            object-fit: contain;
          }
          div {
            width: 100%;
            height: auto;
            position: relative;
            align-items: center;
            display: flex;
            justify-content: center;
            // background: blue;

            label {
              cursor: pointer;
              z-index: 100;
            }
          }
          @media only screen and (max-width: 920px) {
            width: 100%;
          }
        }

        label {
          color: #606060;
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .shipments_select {
        border-radius: 0.625rem;
        width: 100%;
        height: 3.125em;
        gap: 0.625rem;
        display: flex;
        flex-direction: column;

        select {
          border-radius: 10px;
          background-color: #fefefe;
          width: 100%;
          height: auto;
          display: flex;
          flex: 1;
          color: #363539;
          border: none;
          border: 1px solid #dedce9;
          padding: 0.875rem 1rem;

          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          appearance: none;
          -webkit-appearance: none;

          &:focus {
            outline: none;
          }
        }

        label {
          color: #606060;
          font-family: $secondary-font;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
</style>
