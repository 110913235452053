<template>
  <div class="app">
    <div class="app_nav">
      <div v-if="this.$store.state.accessToken" class="nav">
        <nav-bar />

        <Menu
          class="menu"
          :class="{
            open: this.$store.state.openNav,
          }"
        />
      </div>
    </div>

    <div class="app_pages"><router-view /></div>
  </div>
</template>

<script>
import Menu from "./components/menu/MobileSideMenu.vue";
import NavBar from "./components/nav/NavBar.vue";
export default {
  components: {
    Menu,
    NavBar,
  },
  mounted() {
    // this.checkRoute();
  },
  watch: {
    $route: {
      handler() {
        this.$store.dispatch("navMenuHandler", false);
        this.checkRoute();
      },
      immediate: false, // Ensure function runs on initial mount
    },
  },
  methods: {
    checkRoute() {
      if (this.$route.path === "/") {
        this.$router.push("/shipments");
      }
      // console.log(this.$store.state.accessToken);
    },
  },
};
</script>

<style lang="scss">
@import "./styles/main.scss";

.app_nav {
  @media only screen and (max-width: 920px) {
    display: flex;
    margin-bottom: 80px;
  }

  .nav {
    display: none;

    @media only screen and (max-width: 920px) {
      display: flex;
    }

    .menu {
      position: absolute;
      left: 0;
      transform: translateX(-100%);
      transition: 0.1s ease-in-out;
    }

    .open {
      transform: translateX(0);
    }
  }
}

@font-face {
  font-family: "Moderat";
  // ./utils/fonts/Moderate-Font-gefontfree.com_/Moderat-Black.ttf
  src: url("./utils/fonts/Moderate-Font-gefontfree.com_/Moderat-Black.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Moderat";
  src: url("./utils/fonts/Moderate-Font-gefontfree.com_/Moderat-Light.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Moderat";
  src: url("./utils/fonts/Moderate-Font-gefontfree.com_/Moderat-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&display=swap");
.app {
  /* App.css */
  margin: 0 auto;
  /* Center the container */
  max-width: 1440px;
  /* Set maximum width for the entire app */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "Moderat", "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>
